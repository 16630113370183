/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-body-style */
import React from 'react';

import { useDispatch, useSelector } from 'react-redux'; //
import './style.scss';
import { Link } from 'react-router-dom';
const Navbar = () => {

  const dispatch = useDispatch()
  
  let theme = useSelector(state => state.home.theme);
  //if (!theme) {theme = document.cookie}
  theme === 'dark' ? document.body.className = 'dark' : document.body.className = 'light';
  function switchTheme() {
    //let theme = document.body.className;
    //theme === 'dark' ? document.body.className = 'dark' : document.body.className = 'light';
    //document.cookie = `${theme};max-age=604800;domain=localhost`
    //console.log(document.cookie)
    dispatch({ type: "SWITCH_THEME", theme });
  };
  return (
    <header className="header">
      <div className="header-links">
        <Link to="/" className="header-links-item">
          ACCUEIL 
        </Link>
        <Link to="/cv" className="header-links-item">
          CV
        </Link>
        <Link to="/projets" className="header-links-item">
          PROJETS
        </Link>
        <Link to="/helloworld" className="header-links-item">
          DIVERS
        </Link>
        {/*<div className='header-links-item'>*/}
          <p className='header-links-item, header-links-theme' onClick={switchTheme}>x</p>
        {/*</div>*/}
        
      </div>
    </header>
  );
};

Navbar.propTypes = {

};

export default Navbar;
