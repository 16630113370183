import React from 'react';
//import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';

//import { useSelector } from 'react-redux'
import './style.scss';
import './phone.scss';

const Footer = () => {
  //const truc = useSelector(state => state.home.accueil)
  //console.log(truc)
  return (
    <footer className="footer">
      <div className="footer">
        <div className='footer'>
          {/*
          <div className="footer-items">
            <Link to="/cgu" className="footer-items-link">CGU</Link>
            <Link to="/team" className="footer-items-link">TEAM</Link>
          </div>
          */}
        </div>
      </div>
    </footer>
  )

};

Footer.propTypes = {

};

export default Footer;
