/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux';
import reducer from '../../src/reducers';// src/reducers
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

//// on importe les middlewares
//import ajax from '../middlewares/ajax';
//import subscriptionMiddleware from 'src/middlewares/subscriptionMiddleware';
import homeMiddleware from '../middlewares/homeMiddleware';
//import blogMiddleware from '../middlewares/blogMiddleware';
//import videogameMiddleware from 'src/middlewares/videogameMiddleware';
//import deleteUserMiddleware from 'src/middlewares/deleteUserMiddleware';
//import editProfileMiddleware from 'src/middlewares/editProfileMiddleware';
//import searchPromotionsMiddleware from 'src/middlewares/searchPromotionsMiddleware';
//
const persistConfig = {
  key: 'root',
  storage: storage, 
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
  blacklist: ['', ''], //'filters', 'team'
  whitelist: ['theme:'], //'users', 'home', 'site', 'videogame
};

const pReducer = persistReducer(persistConfig, reducer);

// on met bout à bout tous nos middlewares
const middlewares = applyMiddleware(
  //ajax,
  //subscriptionMiddleware,
  homeMiddleware,
  //blogMiddleware,
  //videogameMiddleware,
  //deleteUserMiddleware,
  //editProfileMiddleware,
  //searchPromotionsMiddleware,
);

// on met bout à bout le redux devtools et nos middlewares
// https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancers = composeEnhancers(middlewares);

// on crée le store à qui l'on passe le reducer et les middlewares (avec le devtool)
export const store = createStore(pReducer, enhancers);
export const persistor = persistStore(store);

// export default store;
