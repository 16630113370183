import React from 'react'; //, {useEffect} 

//import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'; // useSelector, 
import './style.scss';
const Menu = () => {
    const dispatch = useDispatch()
    //let boutonA = document.querySelector('.app-helloworld-gb-boutons-ab-a');
    let bouton = new Audio("https://www.aht.li/3709114/bouton.mp3")
    let ascenceur = new Audio("https://www.aht.li/3709115/ascenceur.mp3")
    //let prof = new Audio("https://www.aht.li/3709116/prof.mp3")
    //ascenceur.play()
    
    function handleClick() {//
        ascenceur.pause()
        bouton.play()
        console.log('pouet')
        dispatch({ type: "STAGE_UN" });
    };
    return (
        <div className="app-helloworld">
            <div className='app-helloworld-gb'>
                <div className='app-helloworld-gb-menu'>
                    <div className='app-helloworld-gb-menu-start'>
                        <p className='app-helloworld-gb-menu-start-p'>- Nouvelle partie</p>
                        <img src='https://www.aht.li/3709113/pkb.gif' alt='pokeball clignotante'></img>
                    </div>
                </div>
                <div className='app-helloworld-gb-boutons'>
                    <div className='app-helloworld-gb-boutons-direct'>
                        <div className='app-helloworld-gb-boutons-direct-haut'></div>
                        <div className='app-helloworld-gb-boutons-direct-droite'></div>
                        <div className='app-helloworld-gb-boutons-direct-gauche'></div>
                        <div className='app-helloworld-gb-boutons-direct-bas'></div>
                    </div>
                    <div className='app-helloworld-gb-boutons-ab'>
                        <div className='app-helloworld-gb-boutons-ab-blank1'></div>
                        <div className='app-helloworld-gb-boutons-ab-a' onClick={handleClick}></div>
                        <div className='app-helloworld-gb-boutons-ab-b'></div>
                        <div className='app-helloworld-gb-boutons-ab-blank2'></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Menu.propTypes = {
};

export default Menu;
