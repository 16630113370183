/* eslint-disable import/no-extraneous-dependencies */
import { Routes, Route} from 'react-router-dom'; // BrowserRouter, , Redirect, useLocation , Navigate 
//import PropTypes from 'prop-types';
import React from 'react'; //, { useEffect }
/*
import Search from 'src/containers/Search';
import ProfilePage from 'src/containers/ProfilePage';
import Login from 'src/containers/Login';
import Subscribe from 'src/containers/Login/Subscribe';
import VideoGame from 'src/containers/VideoGame';
import About from 'src/containers/About';
*/
import Home from '../../containers/Home';//'./Home'; //src/containers/Home
//import Blog from '../../containers/Blog';//'./Home'; //src/containers/Home
//import logo from 'src/assets/images/logo_rose_bleu.png';
import Navbar from './Navbar';
import Footer from './Footer';
//import Error404 from './404';
//import Cgu from './Cgu';
import CV from './CV';
import Projets from './Projets';
import Helloworld from './Helloworld';

//import Team from './Team';
//import Login from './Login';
//import Logout from './Logout';
//import Serv from './Home/Serv';
///////////////////////////////////////////////
//import Commands from './Commands';
//import Blog from './Blog';
//import MentionLegales from './MentionLegales';
import '../../fonts/Champagne_et_Limousines.ttf'; 
import './style.scss';
//fetchTags, 
//fetchAdventureGames,
//isConnected, 
//closeBurger,
const App = () => {
  //const location = useLocation();
  //useEffect(closeBurger, [location.pathname]);
  //useEffect(() => {
  //  fetchTags(); 
  //  fetchAdventureGames(18);
  //}, []);
  //useEffect(toto, [toto]);
  //useEffect(getInfo, [getInfo]);
  const handleClick = () => {
    // closeBurger();
  };
  return (
    <div className="app" onClick={handleClick}>
      <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route exact path="/cv" element={<CV />}/>
          <Route exact path="/projets" element={<Projets />}/>
          <Route exact path="/helloworld" element={<Helloworld />}/>
        </Routes>
      <Footer />
    </div>
  );
};

App.propTypes = {};

export default App;
