import React from 'react'; // , { useEffect } 
import { useSelector } from 'react-redux'
//import PropTypes from 'prop-types';
/*import Promotions from 'src/containers/Promotions';
import Filter from 'src/containers/Filter';
import Recommendations from 'src/containers/Recommendations';
*/
import './style.scss';
//import './phone-style.scss';
//import './tablet-style.scss';


import Menu from './Menu';
import Profa from './Profa';
import Profb from './Profb';
import Profc from './Profc';
import Profd from './Profd';
import Profe from './Profe';
import Proff from './Proff';
import Profg from './Profg';
import Pop from './Pop';
import Popneuf from './Popneuf';
import Masterball from './Masterball';
import Waiting from './Waiting';
import Walking from './Walking';

const Helloworld = (/*{
  //getInfo,
  //toto,
  //getConfig,
  //select,
}*/) => {
  //console.log(getInfo)
  //useEffect(getInfo, [getInfo]);
  //useEffect(toto, [toto]);
  //useEffect(select, [select]);
  //useEffect(getConfig, [getConfig]);
  //useEffect(getConfig, [getConfig]);
  //useEffect(getConfig, [getConfig]);
  //const token = useSelector(state => state.home.user.toto);
  //const isLogged = useSelector(state => state.home.user.logged);
  //console.log(isLogged)

  //<iframe id='music-iframe' width="720" height="480" src="https://www.youtube.com/watch?v=chWVKjplFL0" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
  //<iframe width="230" height="170" src="https://www.youtube.com/embed/chWVKjplFL0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  //<p>Ici j'vais créer un blog</p>
  //<p>mais en attendant de pouvoir vous raconter ma vie...</p>
  //<p>voila une des instru que je préfére</p>
  //let prof = new Audio("https://www.aht.li/3709116/prof.mp3")
  const stage = useSelector(state => state.home.stage);
  switch (stage) {
    case 1:
      //prof.play()
      return (
        <Profa />
      );
    case 2:
      return (
        <Profb />
      );
    case 3:
      return (
        <Profc />
      );
    case 4:
      return (
        <Profd />
      );
    case 5:
      return (
        <Profe />
      );
    case 6:
      return (
        <Proff />
      );
    case 7:
      //prof.pause();
      return (
        <Profg />
      );
    case 8:
      return (
        <Pop />
      );
    case 9:
      return (
        <Popneuf />
      );
    case 10:
      return (
        <Masterball />
      );
    case 11:
      return (
        <Waiting />
      );
    case 12:
      return (
        <Walking />
      );
    default:
      return (
        <Menu />
      );
  }
};

/* Helloworld.propTypes = {
  //getInfo: PropTypes.func.isRequired,
}; */

export default Helloworld;