import React from 'react'; // , { useEffect }

import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import Markdown from 'markdown-to-jsx';
//import './phone-style.scss';
//import './tablet-style.scss';
//import Welcome from './Welcome';
//import Dashboard from './Dashboard';
const CV = (/*{
  //getInfo,
  //toto,
  //getConfig,
  //select,
}*/) => {
  //console.log(getInfo)
  //useEffect(getInfo, [getInfo]);
  //useEffect(toto, [toto]);
  //useEffect(select, [select]);
  //useEffect(getConfig, [getConfig]);
  //useEffect(getConfig, [getConfig]);
  //useEffect(getConfig, [getConfig]);
  
  //const isLogged = useSelector(state => state.home.user.logged);
  //console.log(isLogged)
  const dispatch = useDispatch()
  const cv = useSelector(state => state.home.cv);
  !cv && dispatch({ type: "GET_CV" });
  cv && console.log(cv)
  return (
    <div className="cv">
      <div className='cv-intro'>
        <p className='cv-intro-title'><strong>JIMMY GAUBERT</strong></p>
        <p>Développeur Web et Web Mobile</p>
      </div>
      <div className='cv-items'>
        <div className='cv-items-a'>
          <h3>PROFIL</h3>
          {cv ? <Markdown>{cv[1].replace(str => <p>{str}</p>)}</Markdown> : ""}
          <h3>COORDONNÉES</h3>
          contact@truc-bidule.fr
          {/*
          {cv ? <Markdown>{cv[0]}</Markdown> : ""}
          <strong>
          <p>contact@truc-bidule.fr</p>
          <p>machin-chose.fr</p>
          <p>Domicilié à Douarnenez 29100</p>
          <p>LINKEDIN.COM/IN/JIMMY-GAUBERT</p>
          </strong>
          */}
          <div className='cv-items-a-skills'>
            <div className='cv-items-a-skills-hard'>
            <h3>HARD-SKILLS</h3>
            
            <p>Maîtrise de Git et GitHub</p>
            <p>Maîtrise du terminal Linux</p>
            <p>Maîtrise de Visual Studio Code</p>
            
            </div>
            <div className='cv-items-a-skills-soft'>
            <h3>SOFT-SKILLS</h3>
            
            <p>Résilence et détermination</p>
            <p>Esprit d'équipe et médiation</p>
            <p>Très grande curiosité !</p>
            
            </div>
          </div>
          <h3>LANGAGES</h3>
          
          <p>Français C2, Anglais B2, Breton A1,</p>
          <p>JS, SQL, PHP, HTML, CSS, COW...</p>
          
          </div>
          <div className='cv-items-b'>
            <h3>FORMATIONS</h3>
            {cv ? <Markdown>{cv[2].replace('FORMATIONS :', '')}</Markdown> : ""}
            {/*
            <strong>
            <p>DÉVELOPPEUR WEB ET WEB MOBILE - NIVEAU V</p>
            </strong>
            <p className='cv-items-b-italik'><i>O'CLOCK - GRANDE ECOLE DU NUMÉRIQUE | 2021</i></p>
            <strong>
            <p>- Socle (HTML5/CSS3/PHP/JavaScript/SQL)</p>
            <p>- Spécialisation (ES6/React/Redux)</p>
            <p>- Projet en groupe (React/Symfony)</p>
            
            <br></br>
            <p>PRÉVENTION ET SECOURS CIVIQUE - NIVEAU I</p>
            <br></br>
            <p>BREVET D'APTITUDES AUX FONCTIONS D'ANIMATEUR</p>
            </strong>
            */}
          <br></br><br></br>
          
            <h3>TEMPS LIBRE</h3>

            {cv ? <Markdown>{cv[4].replace('TEMPS LIBRE :', '')}</Markdown> : ""}
            {/*
            <strong>
            <p>Modérateur</p>
            <p> Dune : Spice Wars (Shiro Games) | 2022 - Aujourd'hui</p>
            <p> Darksburg (Shiro Games) | 2020 - Aujourd'hui</p>
            <br></br>
            <p>Helper Bénévole</p>
            <p> Nostale (GameForge) | 2015 - 2021</p>
            </strong>
          */}
          </div>
        
          <div className='cv-items-c'>
            <h3>EXPÉRIENCES</h3>
            {cv ? <Markdown>{cv[3].replace('EXPÉRIENCES :', '')}</Markdown> : ""}
            {/*
            <strong>
            <p>STAGIAIRE - DÉVELOPPEUR WEB</p>
            </strong>
            <p className='cv-items-b-italik'><i>MAMAN EST DEBOUT | AOUT 2021 - SEPTEMBRE 2021</i></p>
            <strong>
            <p>2 mois en solo, sans tuteur technique :</p>
            <p>- 2 semaines de conception (Cahier des charges, Wireframes...)</p>
            <p>- 1 semaine de développement</p>
            <p>- 1 semaine de veille (NGINX, Fail2Ban et passage du titre pro)</p>
            <p>- 4 semaines de développement</p>
            <br></br>
            
            <p>STAGIAIRE - FORMATION PROFESSIONNELLE</p>
            </strong>
            <p className='cv-items-b-italik'><i>PROMOGAMING (PROJET D'APOTHÉOSE) | JUIN 2021 - SEPTEMBRE 2021</i></p>
            <strong>
            <p>1 mois à 5 étudiants :</p>
            <p>- 1 semaine de conception</p>
            <p>- 3 semaines de développement</p>
            <br></br>
            <p>STAGIAIRE - TECHNICIEN DE MAINTENANCE INFORMATIQUE</p>
            <p>PC-CLEAN | 2019</p>
            <p>DEV-CRY | 2017</p>
            </strong>
            */}
            {/*<p>(vente et réparation de machines en boutique ou en déplacement)</p>*/}

            <h4 className='cv-pdf'><a href='https://cv.truc-bidule.fr' target="_blank" rel="noreferrer">Télécharger une copie</a></h4>
          </div>
        
      </div>
    </div>
  );
};

CV.propTypes = {
  //getInfo: PropTypes.func.isRequired,
};

export default CV;
