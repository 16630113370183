import React from 'react';

import { useDispatch } from 'react-redux'

import './style.scss';
const Walking = () => {
    
    const dispatch = useDispatch()
    let itemfound = new Audio("https://www.aht.li/3709659/item-found.mp3")
    
    
    itemfound.play()
    function handleClick() {//
        console.log("abab")
        return dispatch({ type: "STAGE_UN" });
    };
    return (
        <div className="app-helloworld">
            <div className='app-helloworld-gb'>
                <div className='app-helloworld-gb-pop'>
                    <div className='app-helloworld-gb-pop-A'>
                        <div className='tile18' id='A1'></div>
                        <div className='tile18' id='A2'></div>
                        <div className='tile10' id='A3'></div>
                        <div className='tile11' id='A4'></div>
                        <div className='tile21' id='A5'></div>
                        <div className='tile10' id='A6'></div>
                        <div className='tile11' id='A7'></div>
                        <div className='tile11' id='A8'></div>
                        <div className='tile12' id='A9'></div>
                    </div>
                    <div className='app-helloworld-gb-pop-B'>
                        <div className='tile10' id='B1'></div>
                        <div className='tile10' id='B2'></div>
                        <div className='tile11' id='B3'></div>
                        <div className='tile11' id='B4'></div>
                        <div className='tile11' id='B5'></div>
                        <div className='tile11' id='B6'></div>
                        <div className='tile11' id='B7'></div>
                        <div className='tile16' id='B8'></div>
                        <div className='tile11' id='B9'></div>
                    </div>
                    <div className='app-helloworld-gb-pop-C'>
                        <div className='tile11' id='C1'></div>
                        <div className='tile3' id='C2'></div>
                        <div className='tile2' id='C3'></div>
                        <div className='tile2' id='C4'></div>
                        <div className='tile2' id='C5'></div>
                        <div className='tile2' id='C6'></div>
                        <div className='tile1' id='C7'></div>
                        <div className='tile11' id='C8'><img src='https://www.aht.li/3708615/shiny_pkm.gif' alt='pkm shiny'></img></div>
                        <div className='tile11' id='C9'></div>
                    </div>
                    <div className='app-helloworld-gb-pop-D'>
                        <div className='tile12' id='D1'></div>
                        <div className='tile4' id='D2'></div>
                        <div className='tile5' id='D3'></div>
                        <div className='tile5' id='D4'></div>
                        <div className='tile5' id='D5'></div>
                        <div className='tile5' id='D6'></div>
                        <div className='tile13' id='D7'></div>
                        <div className='tile2' id='D8'></div>
                        <div className='tile2' id='D9'></div>
                    </div>
                    <div className='app-helloworld-gb-pop-E'>
                        <div className='tile11' id='E1'></div>
                        <div className='tile4' id='E2'></div>
                        <div className='tile5' id='E3'></div>
                        <div className='tile5' id='E4'></div>
                        <div className='tile5' id='E5'></div>
                        <div className='tile25' id='E6'></div>
                        <div className='tile17' id='E7'></div>
                        <div className='tile5' id='E8'></div>
                        <div className='tile5' id='E9'></div>
                    </div>
                    <div className='app-helloworld-gb-pop-F'>
                        <div className='tile12' id='F1'></div>
                        <div className='tile4' id='F2'></div>
                        <div className='tile5' id='F3'></div>
                        <div className='tile5' id='F4'></div>
                        <div className='tile5' id='F5'></div>
                        <div className='tile5' id='F6'></div>
                        <div className='tile19' id='F7'></div>
                        <div className='tile14' id='F8'></div>
                        <div className='tile8' id='F9'></div>
                    </div>
                    <div className='app-helloworld-gb-pop-G'>
                        <div className='tile11' id='G1'></div>
                        <div className='tile9' id='G2'></div>
                        <div className='tile8' id='G3'></div>
                        <div className='tile8' id='G4'></div>
                        <div className='tile8' id='G5'></div>
                        <div className='tile8' id='G6'></div>
                        <div className='tile8' id='G7'></div>
                        <div className='tile7' id='G8'></div>
                        <div className='tile11' id='G9'></div>
                    </div>
                    <div className='app-helloworld-gb-pop-H'>
                        <div className='tile10' id='H1'></div>
                        <div className='tile15' id='H2'></div>
                        <div className='tile15' id='H3'></div>
                        <div className='tile15' id='H4'></div>
                        <div className='tile15' id='H5'></div>
                        <div className='tile15' id='H6'></div>
                        <div className='tile15' id='H7'></div>
                        <div className='tile15' id='H8'></div>
                        <div className='tile11' id='H9'></div>
                    </div>
                    <div className='app-helloworld-gb-pop-I'>
                        <div className='tile11' id='I1'></div>
                        <div className='tile15' id='I2'></div>
                        <div className='tile15' id='I3'></div>
                        <div className='tile15' id='I4'></div>
                        <div className='tile15' id='I5'></div>
                        <div className='tile15' id='I6'></div>
                        <div className='tile15' id='I7'></div>
                        <div className='tile15' id='I8'></div>
                        <div className='tile12' id='I9'></div>
                    </div>
                </div>
                
                <div className='textbox'>
                    <p className='textbox-p1'>Vous ramassez la MasterBall . . .</p>
                </div>
                
                <div className='app-helloworld-gb-boutons'>
                    <div className='app-helloworld-gb-boutons-direct'>
                        <div className='app-helloworld-gb-boutons-direct-haut'></div>
                        <div className='app-helloworld-gb-boutons-direct-droite'></div>
                        <div className='app-helloworld-gb-boutons-direct-gauche'></div>
                        <div className='app-helloworld-gb-boutons-direct-bas'></div>
                    </div>
                    <div className='app-helloworld-gb-boutons-ab'>
                        <div className='app-helloworld-gb-boutons-ab-blank1'></div>
                        <div className='app-helloworld-gb-boutons-ab-a' onClick={handleClick}></div>
                        <div className='app-helloworld-gb-boutons-ab-b'></div>
                        <div className='app-helloworld-gb-boutons-ab-blank2'></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Walking.propTypes = {
};

export default Walking;
