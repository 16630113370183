import React from 'react'; // , { useEffect } 
//import { useSelector } from 'react-redux'
//import PropTypes from 'prop-types';
/*import Promotions from 'src/containers/Promotions';
import Filter from 'src/containers/Filter';
import Recommendations from 'src/containers/Recommendations';
*/

import { useSelector } from 'react-redux'; //useDispatch, 
import './style.scss';
//import './phone-style.scss'; 
//import './tablet-style.scss';
//import Welcome from './Welcome';
//import Dashboard from './Dashboard';
const Projets = (/*{
  //getInfo,
  //toto,
  //getConfig,
  //select,
}*/) => {
  //console.log(getInfo)
  //useEffect(getInfo, [getInfo]);
  //useEffect(toto, [toto]);
  //useEffect(select, [select]);
  //useEffect(getConfig, [getConfig]);
  //useEffect(getConfig, [getConfig]);
  //useEffect(getConfig, [getConfig]);
  //const token = useSelector(state => state.Projets.user.toto);
  //const isLogged = useSelector(state => state.Projets.user.logged);
  //console.log(isLogged) 
  //const dispatch = useDispatch()
  
  let theme = useSelector(state => state.home.theme);
  return (
    <div className='app-projets-items'>

      <div className='app-projets-items-box'>
        <img className='app-projets-items-logo' src={theme === 'dark' ? 'https://www.aht.li/3746793/sgwhitebeta.png' : 'https://www.aht.li/3746794/sgblackbeta.png'} alt='logo-de-projet' id='firstbox' />
        <div>
          <a href="https://discord-bot.shirogames.com" target="_blank" rel="noreferrer"><h3 className='app-projets-items-title'>MES APPS POUR SHIRO</h3></a>
          <p className='app-projets-items-desc'>En plus de modérer leurs serveurs de discussion, j'ai aussi développé sur mon temps libre des applications que je continue de maintenir assez régulièrement. Le projet entier contient deux back-end ainsi qu'un front-end. Je met à jours les apps dans un repo privé que je partage avec l'administrateur système de l'entreprise, Maurice Doison, ainsi qu'avec Nicolas Cannasse, l'un des co-fondateur de Shiro Games. Ces applications sont ensuite utilisées pour modérer la communauté de joueurs.</p>
        </div>
        {/*<img className='app-projets-items-scrollgif' src='https://www.aht.li/3710669/scrollcursor.gif' alt='gif qui annonce que le scroll est possible pour passer au projet suivant'></img>*/}
      </div>

      <div className='app-projets-items-box'>
        <img className='app-projets-items-logo' src='https://www.aht.li/3746780/truc-bidule-fr.png' alt='logo-de-projet' id='firstbox' />
        <div>
          <a href="https://ripley.link" target="_blank" rel="noreferrer"><h3 className='app-projets-items-title'>TRUC-BIDULE.FR</h3></a>
          <p className='app-projets-items-desc'>C'est le site sur lequel vous naviguez ! Très souvent quand je donne mon adresse e-mail on me dit "Mais Monsieur, il faut renseigner une vraie adresse e-mail" sur tel papier. Les gens sont très surpris de découvrir que machin arobase est valide. On m'a reproché le fait que le nom de domaine ne fait pas très pro... Moi je pense qu'au contraire pour travailler dans ce domaine, il faut de la fantaisie et de l'imaginaire... De plus, je tiens à me présenter tel que je suis, sans faire samblant pour plaire. Laissez moi être productif, même si je déborde ! J'arrange ce site de temps en temps. Dernièrement j'ai ajouté un second thème ainsi qu'un lien pour télécharger mon cv en pdf...</p>
        </div>
      </div>

      <div className='app-projets-items-box'>
        <img className='app-projets-items-logo' src='https://www.aht.li/3745704/logo-preda-grey.png' alt='logo-de-projet' id='firstbox' />
        <div>
          <a href="https://ripley.link" target="_blank" rel="noreferrer"><h3 className='app-projets-items-title'>RIPLEY</h3></a>
          <p className='app-projets-items-desc'> Phishing, token-grabers, coin-scams, malwares... Ripley est un bot qui protège vos serveurs discord. S'il est accompagné d'un dashboard, il a aussi ses endpoints d'api sur lequels nous reportons les liens suspicieux trouvés par lui-même ou par la communauté. Nous jouons également avec les ip que nous enregistrons et quelques APIs pour ainsi augmenter les détections et offrir d'avantage de sécurité aux utilisateurs qui nous font confiance ! Ce projet est né d'un travail d'équipe, sur notre temps libre, mais surtout d'un ras-le-bol des self-bots et comptes piratés qui viennent spammer leurs merdes sur les tchats de nos discussions publiques. Les méthodes du bot sont agressives. </p>
        </div>
      </div>

      <div className='app-projets-items-box'>
        <img className='app-projets-items-logo' src='https://www.aht.li/3746638/logo_med_grey.png' alt='logo-de-projet' />
        <div>
          <a href="https://mamanestdebout.org" target="_blank" rel="noreferrer"><h3 className='app-projets-items-title'>MAMAN EST DEBOUT</h3></a>
          <p className='app-projets-items-desc'>Site web de Maman est Debout (Association de loi 1901), qui milite pour les droits des familles. Cette association dénonce la maltraitance institutionelle, les séparation de proches et les déportations forcées, la privation de liberté, l'emprise... Bref, j'ai kiffé travailler sur ce projet !</p>
        </div>
      </div>

      <div className='app-projets-items-box'>
        <img className='app-projets-items-logo' src='https://www.aht.li/3696383/chicken.png' alt='logo-de-projet' />
        <div>
          <h3 className='app-projets-items-title'>CHICKEN</h3>
          <p className='app-projets-items-desc'>Le bot du serveur discord officiel du jeu Darksburg crée par Shiro Games. Si le bot nous est très utile dans la modération, il propose aussi des quêtes journalières aux joueurs qui doivent terminer des parties en jeu. Chicken possède aussi un shop et un wiki très bien fourni.</p>
        </div>
      </div>

      <div className='app-projets-items-box'>
        <img className='app-projets-items-logo' src='https://www.aht.li/3547413/icon.png' alt='logo-de-projet' />
        <div>
          <h3 className='app-projets-items-title'>MELTIN*</h3>
          <p className='app-projets-items-desc'>Ce tout premier projet m'a donné envie de reprendre des études. En effet, c'est avec lui que j'ai découvert le développement ! Ce bot discord donnait des soluces de jeux et était présent sur plus de 230 serveurs. Trop studieux dans mes études, je n'ai pas pris le temps de le mettre à jour.</p>
        </div>
      </div>

    </div>
  );
};

Projets.propTypes = {
  //getInfo: PropTypes.func.isRequired,
};

export default Projets;
