import React from 'react';
//import { Link } from 'react-router-dom';
import './style.scss';

const Home = () => {
  let date = new Date();
  console.log(date.getFullYear())
  let tourautourdusoleil = date.getFullYear() - (1996);
  return (
    <div className="home">
      <div className='home-ovni'>
        lC
      </div>
      <div className='home-title'>
        <strong>JE SUIS ARRIVÉ SUR TERRE</strong>
      </div>
      <div className='home-content'>
      1 mois et demi à l'avance, en 1996. J'ai eu un milieu de vie pas facile. J'ai été abducté, mais je préfère en parler <a className='home-content-underline' href='https://www.facebook.com/MamanEstDebout/photos/1451645268538784' target="_blank" rel="noreferrer">ailleurs</a><span></span>... Depuis, j'ai tourné {tourautourdusoleil} fois autour du soleil. J'aime les pâtes au saumon. J'ai 3 chats. Je collectionne les cailloux. J'aime les jeux de rôles et la modération. Je passe mon temps libre à développer des <a href="/projets" target="_blank" rel="noreferrer">trucs</a>. 
      </div>

      <div className='home-ovni'>
        m
      </div>
      <div className='home-title'>
        <strong>DES ETOILES PLEIN LES YEUX</strong>
      </div>
      <div className='home-content'>
         Je sors d'une <a href="https://oclock.io/" target="_blank" rel="noreferrer">très bonne école</a>. Tous les jours, j'avais hâte d'être le lendemin pour me réveiller et aller en cours. Le soir je voulais pas partir. Pour la première fois de ma vie, j'ai aimé apprendre. J'ai apprécié voir mes profs et l'équipe péda tout donner. Confiant pendant mon passage devant le jury, j'ai obtenu mon diplôme les doigts dans le nez. Bref, j'ai dégusté et je suis passé d'un niveau 3èm à un pallier 5 en 6 mois. J'ai surkiffé de ouf. Depuis, j'apprends plein de nouvelles choses, et plus j'en apprends, plus j'ai soif d'apprendre... J'ai été formé pour faire le métier de mes rêves !
      </div>

      <div className='home-ovni'>
        e
      </div>
      <div className='home-title'>
        <strong>J'AI PAS ENCORE DEV LA TELEPORTATION</strong>
      </div>
      <div className='home-content'>
        Et comme je n'ai ni permis, ni véhicule et que j'habite une petite ville bretonne, je cherche un CDD ou un CDI en remote...  Il faut bien commencer quelque part ! Junior, je souhaiterais travailler en équipe sur des projets vertueux et si possible qui aident les gens. Il sagirait de ne pas dev skynet.
      </div>
    </div>
  );
};

Home.propTypes = {
  //getInfo: PropTypes.func.isRequired,
};

export default Home;
