import { connect } from 'react-redux';
import Home from '../../components/App/Home';//src/components/App/Home/
import { stageUn } from '../../actions';

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => ({
  stageUn: () => {
    dispatch(stageUn());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
 