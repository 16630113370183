import React from 'react';

import { useDispatch } from 'react-redux'; // useSelector

import './style.scss';

const Profe = () => {
    const dispatch = useDispatch()
    let bouton = new Audio("https://www.aht.li/3709114/bouton.mp3")
    //let prof = new Audio("https://www.aht.li/3709116/prof.mp3")
    //prof.play()

    function handleClick() {//
        bouton.play()
        return dispatch({ type: "STAGE_SIX"});
    };
    return (
        <div className="app-helloworld">
            <div className='app-helloworld-gb'>
                <div className='app-helloworld-gb-prof'>
                    <div className='app-helloworld-gb-prof-textbox'>
                        <p className='app-helloworld-gb-prof-textbox-p1'>Les coccinelles sont des coléoptères.</p>
                        <p className='app-helloworld-gb-prof-textbox-p2'>L'aventure qui vous attends, vous attends.</p>
                    </div>
                </div>
                <div className='app-helloworld-gb-boutons'>
                    <div className='app-helloworld-gb-boutons-direct'>
                        <div className='app-helloworld-gb-boutons-direct-haut'></div>
                        <div className='app-helloworld-gb-boutons-direct-droite'></div>
                        <div className='app-helloworld-gb-boutons-direct-gauche'></div>
                        <div className='app-helloworld-gb-boutons-direct-bas'></div>
                    </div>
                    <div className='app-helloworld-gb-boutons-ab'>
                        <div className='app-helloworld-gb-boutons-ab-blank1'></div>
                        <div className='app-helloworld-gb-boutons-ab-a' onClick={handleClick}></div>
                        <div className='app-helloworld-gb-boutons-ab-b'></div>
                        <div className='app-helloworld-gb-boutons-ab-blank2'></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Profe.propTypes = {
};

export default Profe; 
