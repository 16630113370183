export const SWITCH_THEME = 'SWITCH_THEME';
export const switchTheme = (theme) => ({
  type: SWITCH_THEME,
  theme
});

export const GET_CV = 'GET_CV';
export const getCV = () => ({
  type: GET_CV,
})
export const STORE_CV = 'STORE_CV';
export const storeCV = (data) => ({
  type: STORE_CV,
  data,
})

export const STAGE_UN = 'STAGE_UN';
export const stageUn = () => ({
  type: STAGE_UN,
});
export const STAGE_DEUX = 'STAGE_DEUX';
export const stageDeux = () => ({
  type: STAGE_DEUX,
});
export const STAGE_TROIS = 'STAGE_TROIS';
export const stageTrois = () => ({
  type: STAGE_TROIS,
}); 
export const STAGE_QUATRE = 'STAGE_QUATRE';
export const stageQuatre = () => ({
  type: STAGE_QUATRE,
}); 
export const STAGE_CINQUE = 'STAGE_CINQUE';
export const stageCinque = () => ({
  type: STAGE_CINQUE,
}); 
export const STAGE_SIX = 'STAGE_SIX';
export const stageSix = () => ({
  type: STAGE_SIX,
}); 
export const STAGE_SEPT = 'STAGE_SEPT';
export const stageSept = () => ({
  type: STAGE_SEPT,
}); 
export const STAGE_HUIT = 'STAGE_HUIT';
export const stageHuit = () => ({
  type: STAGE_HUIT,
}); 
export const STAGE_NEUF = 'STAGE_NEUF';
export const stageNeuf = () => ({
  type: STAGE_NEUF,
}); 
export const STAGE_DIX = 'STAGE_DIX';
export const stageDix = () => ({
  type: STAGE_DIX,
}); 
export const STAGE_ONZE = 'STAGE_ONZE';
export const stageOnze = () => ({
  type: STAGE_ONZE,
}); 

export const STAGE_DOUZE = 'STAGE_DOUZE';
export const stageDouze = () => ({
  type: STAGE_DOUZE,
}); 
export const STAGE_TREIZE = 'STAGE_TREIZE';
export const stageTreize = () => ({
  type: STAGE_TREIZE,
}); 