import {
  STORE_CV,
  STAGE_UN,
  STAGE_DEUX,
  STAGE_TROIS,
  STAGE_QUATRE,
  STAGE_CINQUE,
  STAGE_SIX,
  STAGE_SEPT,
  STAGE_HUIT,
  STAGE_NEUF,
  STAGE_DIX,
  STAGE_ONZE,
  STAGE_DOUZE,
  STAGE_TREIZE,
  SWITCH_THEME,
} from '../actions';
const initialState = {
  stage: 0,
  theme: "dark",
};
let prof = new Audio("https://www.aht.li/3709116/prof.mp3")
//let tr = new Audio("https://www.aht.li/3709657/Team_Rocket.mp3")
let itemfound = new Audio("https://www.aht.li/3709659/item-found.mp3")
let chill = new Audio("https://www.aht.li/3709660/Arceus.mp3")
const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case STORE_CV: {
      let cvArray = [];
      Object.entries(action.data).forEach(([key, value]) => {cvArray.push(value)})
      return {
        ...state,
        cv: cvArray,
      };
    }
    case SWITCH_THEME: {
      console.log(action.theme)
      let themeToSwitch;
      action.theme === 'light' ? themeToSwitch='dark' : themeToSwitch='light';
      return {
        ...state,
        theme: themeToSwitch,
      };
    }
    case STAGE_UN: {
      //console.log("a")
      //console.log(action)
      //console.log(state)

      prof.play()
      return {
        ...state,
        stage: 1,
      };
    }
    case STAGE_DEUX: {
      //console.log("a")
      //console.log(action)
      //console.log(state)

      return {
        ...state,
        stage: 2,
      };
    }
    case STAGE_TROIS: {
      //console.log("a")
      //console.log(action)
      //console.log(state)
      return {
        ...state,
        stage: 3,
      };
    }
    case STAGE_QUATRE: {
      //console.log("a")
      //console.log(action)
      //console.log(state)
      return {
        ...state,
        stage: 4,
      };
    }
    case STAGE_CINQUE: {
      //console.log("a")
      //console.log(action)
      //console.log(state)
      return {
        ...state,
        stage: 5,
      };
    }
    case STAGE_SIX: {
      //console.log("a")
      //console.log(action)
      //console.log(state)
      //prof.pause()
      return {
        ...state,
        stage: 6,
      };
    }
    case STAGE_SEPT: {
      //console.log("a")
      //console.log(action)
      //console.log(state)
      prof.pause()
      itemfound.play()
      return {
        ...state,
        stage: 7,
      };
    }
    case STAGE_HUIT: {
      //console.log("a")
      //console.log(action)
      //console.log(state)
      chill.play();

      return {
        ...state,
        stage: 8,
      };
    }
    case STAGE_NEUF: {
      //console.log("a")
      //console.log(action)
      //console.log(state)
      //chill.play();

      return {
        ...state,
        stage: 9,
      };
    }
    case STAGE_DIX: {
      //console.log("a")
      //console.log(action)
      //console.log(state)
      //chill.play();
      return {
        ...state,
        stage: 10,
      };
    }
    case STAGE_ONZE: {
      //console.log("a")
      //console.log(action)
      //console.log(state)
      //chill.play();
      return {
        ...state,
        stage: 11,
      };
    }
    case STAGE_DOUZE: {
      //console.log("a")
      //console.log(action)
      //console.log(state)
      //chill.play();
      return {
        ...state,
        stage: 12,
      };
    }
    case STAGE_TREIZE: {
      //console.log("a")
      //console.log(action)
      //console.log(state)
      //chill.play();
      return {
        ...state,
        stage: 13,
      };
    }
    //case STORE_INFO: {
    //  //console.log("a")
    //  //console.log(action)
    //  //console.log(state)
    //  return {
    //    ...state,
    //    accueil: action.home,
    //  };
    //}
    //case STORE_TOTO: {
    //  //console.log("a")
    //  //console.log(action)
    //  //console.log(state)
    //  //console.log(action)
    //  return {
    //    ...state,
    //    user: {logged: 'yes', id: action.user, toto: action.toto},
    //    guilds: action.guilds,
    //    //logged: "yes",
    //    //id: action.user,
    //    //toto: action.toto,
    //  };
    //}
    //case STORE_CONFIG: {
    //  //console.log(action)
    //  return {
    //    ...state,
    //    guilds: action.guilds,
    //    //selected: action.guilds[0]
    //    //logged: "yes",
    //    //id: action.user,
    //    //toto: action.toto,
    //  };
    //}
    //case SELECT : {
    //  //console.log(action)
    //  return {
    //    ...state,
    //    selected: action.selected,
    //    //selected: action.guilds[0]
    //    //logged: "yes",
    //    //id: action.user,
    //    //toto: action.toto,
    //  };
    //}
    //case STORE_SELECTION: {
    //  //console.log("A : " + state.home.guilds)
    //  //console.log("Selected : "+ action.selected)
    //  //const guildSelected = state.home.guilds.map((g) => {
    //  //  if (g.guild_id === action.guild) {
    //  //    return g;
    //  //  }
    //  //  return action.selected;
    //  //});
    //  return {
    //    ...state,
    //    selected: action.selected,
    //  };
    //}
    //case SET_VOTE: {
    //  return {
    //    ...state,
    //    vote: action.val
    //  };
    //}
    default:
      return state;
  }
};

export default reducer;
